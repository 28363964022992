<template>
  <paginate
    v-show="visible"
    :page-count="count"
    :value="page"
    :click-handler="onChangePage"
    :prev-text="'Prev'"
    :next-text="'Next'"
    container-class="pagination-container"
    page-class="pagination-page"
    next-class="pagination-next"
    prev-class="pagination-prev"
  >
  </paginate>
</template>

<script>
export default {
  name: 'APagination',
  props: {
    visible: Boolean,
    withoutRoute: {
      type: Boolean,
      default(){
        return false;
      }
    },
    count: Number,
    callback: Function,
    onCreated: Function,
    page: Number
  },
  watch: {
    $route(to, from) {
      if (to.query.page !== from.query.page && !this.withoutRoute) {
        this.callback(!isNaN(parseInt(to.query.page)) && +to.query.page > 1 ? +to.query.page : 1);
      }
    }
  },
  methods: {
    onChangePage(num) {
      if(this.withoutRoute) {
        this.callback(num);
      } else {
        if (num !== 1) {
          this.$router.push({query: {...this.$route.query, page: num}});
        } else {
          this.$router.push({query: {...this.$route.query, page: null}})
        }
      }
    }
  },
  created() {
    if(!this.withoutRoute) {
      const {page} = this.$route.query;
      if (isNaN(parseInt(page)) || +page <= 1) {
        if (page) {
          this.$router.push({query: {}});
        }
        this.callback(1);
      } else {
        this.callback(page ? +page : 1);
      }
    } else {
      this.callback(1);
    }
  }
}
</script>

<style lang="scss">
.pagination-container {
  display: flex;
  align-items: center;
  padding: 16px
}

.pagination-prev, .pagination-next {
  &.disabled a {
    background-color: $c-grey;
    border-color: $c-grey;
    color: #FFF;
    cursor: not-allowed;

    &:hover {
      background-color: $c-grey;
      border-color: $c-grey;
    }
  }

  a {
    display: inline-block;
    padding: 6px 12px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    border: 1px solid $c-blue;
    font-weight: 600;
    background-color: $c-blue;
    color: #FFF;

    &:hover {
      background-color: $c-dark-blue;
      border-color: $c-dark-blue;
    }
  }
}

.pagination-next a {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.pagination-page {
  &.active a, a:hover {
    background-color: $c-blue;
    border-color: $c-blue;
    color: #FFF
  }

  a {
    border-left: .5px solid;
    border-right: .5px solid;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: $c-grey;
    padding: 6px 15px;
    display: inline-block;
  }
}
</style>
